import React from 'react';
import { Wrapper } from './styles';

const NavbarLinks = ({ desktop }) => (
  <Wrapper desktop={desktop}>
    <a href="https://app.videobooth.io/signup">Sign Up</a>
    <a href="https://app.videobooth.io/login">Log In</a>
  </Wrapper>
);

export default NavbarLinks;
