module.exports = {
  defaultTitle: 'VideoBooth.io',
  logo: 'https://portfolio.smakosh.com/favicon/favicon-512.png',
  author: 'Sofia Levin',
  url: 'https://videobooth.io',
  defaultDescription: 'VideoBooth.io - Get practice answering interview questions',
  socialLinks: {
    twitter: 'http://www.twitter.com/sofiaflevin',
    github: 'https://github.com/sofialevin',
    linkedin: 'https://www.linkedin.com/in/sofiaflevin/',
    instagram: 'https://instagram.com/sofiaflevin',
  },
  googleAnalyticsID: 'UA-173129072-1',
  themeColor: '#93D800',
  backgroundColor: '#93D800',
  foundingDate: '2020',
};
